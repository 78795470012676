/* eslint-disable complexity */
import React, { useCallback, useState, useEffect, memo } from 'react'
import { Grid, Box, GridDirection } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useModal } from '~/utils/useModal'
import useLeadContactWithWarning from '~/hooks/useLeadContactWithWarning'
import { Send, SentSucess } from '~/components/core/Icons'
import Typography from '~/components/core/Typography'
import { PaymentsProvider } from '~/components/modules/PaymentsProvider/context'
import { useSendLeadMessage } from '../data/use-send-lead-message/use-send-lead-message'
import ReachLimitModal from '../../components/ReachLimitModal'
import UpdatePlanModal from '../../components/UpdatePlanModal'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'

export interface Props {
  leadId: string
  leadName?: string
  sendCount?: number
  textDirection?: GridDirection
  onSendMessage?: () => void
}

const isReachLimit = (sendCount = 0, remainingNumberOfClicks = 0) => sendCount >= 3 || remainingNumberOfClicks <= 0
const isAlreadySent = (sendCount = 0) => sendCount >= 1

const SendMessage = ({
  leadId, leadName, sendCount, textDirection = 'column', onSendMessage
}: Props): JSX.Element => {
  const { userDetails } = React.useContext(ViewerProvider)
  const { isAgent } = userDetails
  const { remainingNumberOfClicks, refetch, currentPlan } = React.useContext(PaymentsProvider)
  const isAgentNoPremium = isAgent && currentPlan !== 'premium'
  const [sent, setSent] = useState(isAlreadySent(sendCount))
  const [reachedLimit, setReachedLimit] = useState(isReachLimit(sendCount, remainingNumberOfClicks))
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams<{ id: string }>()
  const { showModal, hideModal, isOpen } = useModal()
  const { showModal: showUpdateModal, hideModal: hideUpdateModal, isOpen: isUpdateModalOpen } = useModal()
  const { execute, loading } = useSendLeadMessage({ leadId, assetId: id })
  const isDisabled = reachedLimit || loading

  useEffect(() => {
    setReachedLimit(isReachLimit(sendCount, remainingNumberOfClicks))
  }, [sendCount, remainingNumberOfClicks])

  const handleSubmit = useCallback(() => {
    if (isDisabled) {
      return
    }

    execute().then((res: any) => {
      if (res.message?.includes('contacted 3 times already')) {
        setReachedLimit(true)

        return showModal()
      }

      if ('timesOwnerContactedSearcher' in res) {
        setSent(true)

        if (res.timesOwnerContactedSearcher === 3) {
          setReachedLimit(true)
        }

        refetch()
        onSendMessage?.()

        return enqueueSnackbar(`המודעה נשלחה בהצלחה ל${leadName}`, {
          variant: 'success'
        })
      }

      throw new Error()
    }).catch(() => {
      return enqueueSnackbar(`שגיאה, אנא נסו לשלוח הודעה ל${leadName} מאוחר יותר`, {
        variant: 'error'
      })
    })
  }, [execute, enqueueSnackbar, leadName, showModal, isDisabled, refetch, onSendMessage])

  const [warningModal, getContactDetails] = useLeadContactWithWarning({ leadId, onSubmit: handleSubmit, sendCount })
  const getSendIcon = useCallback(() => {
    const iconColor = reachedLimit ? '#949090' : '#4BB5AF'

    if (sent || isAlreadySent(sendCount)) {
      return <SentSucess color={iconColor} />
    }

    return <Send color={iconColor} />
  }, [sendCount, reachedLimit, sent])

  const handleClick = useCallback(() => {
    if (!remainingNumberOfClicks && isAgentNoPremium) {
      return showUpdateModal()
    }

    if (Number(sendCount) >= 3) {
      return showModal()
    }

    if (isDisabled) {
      return
    }

    getContactDetails()
  }, [isDisabled, getContactDetails, sendCount, showModal, isAgentNoPremium, showUpdateModal, remainingNumberOfClicks])

  return (
    <>
    <Box width='max-content' style={{ cursor: reachedLimit ? 'default' : 'pointer' }} onClick={handleClick}>
      <Grid container spacing={textDirection === 'column' ? 0 : 1} direction={textDirection} alignItems='center'>
        <Grid item style={{ display: 'inline-flex', height: '27.42px', alignItems: 'center' }}>
          {
            loading ? <CircularProgress size='23px' /> : getSendIcon()
          }
        </Grid>
        <Grid item alignItems='center' justify='center' style={textDirection === 'column' ? { display: 'inline-flex', minWidth: '76px' } : {}}>
          <Typography color='textPrimary' style={{ fontSize: '12px' }}>
            {sent || isAlreadySent(sendCount)
            ? `נשלח`
            : 'שליחת המודעה'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <ReachLimitModal isOpen={isOpen} onClose={hideModal} />
    <UpdatePlanModal isOpen={isUpdateModalOpen} onClose={hideUpdateModal} />
    {warningModal}
    </>
  )
}

export default memo(SendMessage)
