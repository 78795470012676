import React from 'react'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import { useTranslation } from 'react-i18next'

import Typography from '~/components/core/Typography'

const PaymentsIndicatorLoader: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Grid
      alignItems='center'
      container
      spacing={1}
      wrap='nowrap'
    >
      <Grid item><Typography color='textPrimary' size='xlarge' weight='bold' noWrap>{t('plans-type-label')}:</Typography></Grid>
      <Grid item container>
        <Skeleton animation='wave' width={200} height={30} />
      </Grid>
      <Grid item container>
        <Skeleton variant='rect' animation='wave' width={88} height={20} />
      </Grid>
    </Grid>
  )
}

export default PaymentsIndicatorLoader
