import React from 'react'
import { Grid, CardActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useHistory, useParams } from 'react-router-dom'

import Modal from '~/components/core/Modal'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { PaymentsProvider } from '~/components/modules/PaymentsProvider/context'

export interface Props {
  onClose: () => void
  isOpen: boolean
}

const UpdatePlanModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const { t } = useTranslation()
  const { currentPlan } = React.useContext(PaymentsProvider)
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const handleClick = () => {
    history?.push(`/plans/asset/${id}/${currentPlan}`)
    onClose()
  }

  return (
    <Modal open={isOpen}>
      <Modal.Title onClose={onClose}>
      הודעה
      </Modal.Title>
      <Modal.Content dividers>
        <Grid container direction='column' spacing={1}>
          <Grid item container justify='center'>
            <ErrorOutlineIcon color='secondary' fontSize='large' style={{ height: 150, width: 'auto', marginBottom: 20 }} />
          </Grid>
          <Grid item>
            <Typography size='large'>
            כדי להמשיך לשלוח את המודעה למחפשים המתאימים שדרגו לאחת מחבילות התשלום שלנו
            </Typography>
          </Grid>
        </Grid>
      </Modal.Content>
        <Modal.Actions>
          <CardActions style={{ width: '100%' }}>
            <Button size='small' variant='text' onClick={onClose}>
              <Typography size='medium'>
                {t('close')}
              </Typography>
            </Button>
            <Button size='small' color='primary' variant='text' onClick={handleClick}>
              <Typography size='medium' weight='bold'>
                שדרג
              </Typography>
            </Button>
          </CardActions>
        </Modal.Actions>
    </Modal>
  )
}

export default UpdatePlanModal
