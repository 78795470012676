import React from 'react'
import useFetch, { CachePolicies } from 'use-http'

import { ViewerProvider } from './context'
import { rollbarError } from '~/utils/rollbar'
import PageLoader from '~/components/core/PageLoader'
import useNewLeadsCache from '~/hooks/useNewLeadsCache'
import useNewSearchLeadsCache from '~/hooks/useNewSearchLeadsCache'
import { MinimalAsset } from '~/types/Asset'
import { MinimalSearch } from '~/types/Search'

interface Props {
  baseUrl?: string
  children: React.ReactElement
}

// eslint-disable-next-line complexity
const ViewerProviderWrapper: React.FC<Props> = ({ children }) => {
  const { loading: assetsLoading, error: assetsError, data: assets, get: refetchAssets } = useFetch<MinimalAsset[]>(`/api/v3/assets`, { cachePolicy: CachePolicies.NO_CACHE }, [])
  const { loading: searchesLoading, error: searchesError, data: searches, get: refetchSearches } = useFetch<MinimalSearch[]>(`/api/v2/searcher/searches`, { cachePolicy: CachePolicies.NO_CACHE }, [])
  const { loading: userDetailsLoading, error: userDetailsError, data: userDetails, get: refetchUserDetails } = useFetch<any>(`/users/userDetails`, { cachePolicy: CachePolicies.NO_CACHE }, [])
  const { put: updateUserDetails, loading: updateUserDetailsLoading } = useFetch<any>(`/users/updateUserDetails`, { cachePolicy: CachePolicies.NO_CACHE })

  const [newAssetLeads, resetAssetNewLeads] = useNewLeadsCache(assets)
  const [newSearchLeads, resetSearchNewLeads] = useNewSearchLeadsCache(searches)

  if ((!assets && assetsLoading) || (!searches && searchesLoading) || (!userDetails && userDetailsLoading)) {
    return <PageLoader invisible open />
  }

  if (searchesError || assetsError || userDetailsError) {
    rollbarError({
      error: searchesError || assetsError
    })
  }

  return (
    <ViewerProvider.Provider
      value={{
        assets,
        searches,
        userDetails,
        updateUserDetails,
        refetchSearches,
        refetchAssets,
        refetchUserDetails,
        refetchLoading: searchesLoading || assetsLoading,
        refetchUserDetailsLoading: userDetailsLoading || updateUserDetailsLoading,
        resetAssetNewLeads,
        newAssetLeads,
        newSearchLeads,
        resetSearchNewLeads
      }}
    >
      {children}
    </ViewerProvider.Provider>
  )
}

export default ViewerProviderWrapper
