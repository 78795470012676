/* eslint-disable complexity */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { useBreakpoint } from '~/utils/breakpoints'
import Link from '~/components/core/Link'
import Button from '~/components/core/Button'
import Typography from '~/components/core/Typography'
import { PaymentsProvider } from '~/components/modules/PaymentsProvider/context'
import Loader from './Loader'

const DAYS_TO_ADD = 28

interface Props {
  id?: string
  hasLeads?: boolean
}

const PaymentsIndicator: React.FC<Props> = ({ id, hasLeads }) => {
  const { t } = useTranslation()
  const {
    status, primiumDaysLeft, loading, currentPlan
  } = React.useContext(PaymentsProvider)
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  let primiumTimeLeft: string | number | undefined = primiumDaysLeft

  let primiumTimeLeftText = t('plans-daysLeft')

  if (!status || (currentPlan === 'free' && !hasLeads)) return null

  if (primiumTimeLeft === 1 && status?.premiumStartDate) {
    const newDate = moment(new Date(status.premiumStartDate as string)).add(DAYS_TO_ADD, 'days')

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    primiumTimeLeft = newDate.locale('he').endOf(newDate).fromNow().split('בעוד')[1]

    primiumTimeLeftText = t('plans-unlimited')
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Grid
      alignItems={isCompactLayout ? 'flex-start' : 'center'}
      direction={isCompactLayout ? 'column' : 'row'}
      container
      spacing={isCompactLayout ? 0 : 1}
      wrap='nowrap'
    >
      <Grid item><Typography color='textPrimary' size='xlarge' weight='bold' noWrap>{`${t('package')} ${t(`sellerPlans.type.${currentPlan}`)}`}:</Typography></Grid>
      <Grid item container>
        <Grid
          container
          alignItems='center'
          spacing={1}
          wrap='nowrap'
        >
          <Grid item><Typography color='textPrimary' size='large' noWrap>{t('plans-haveLeft')}</Typography></Grid>
          <Grid item>
            <Typography color='primary' size='xlarge' weight='bold'>
              {
                (
                  (primiumDaysLeft && primiumDaysLeft > 0) && primiumTimeLeft) ||
                  status.remainingNumberOfClicks
              }
            </Typography>
          </Grid>
          <Grid item><Typography color='textPrimary' size='large' noWrap>{(primiumDaysLeft && primiumDaysLeft > 0) ? primiumTimeLeftText : t('plans-clicksLeft')}</Typography></Grid>
          <Grid item>
            <Link color='primary' to={`/plans/asset/${id}/${currentPlan}`}>
              <Button variant='contained' color='primary' size='small' onClick={() => {}}>
                <Typography size='medium' weight={isCompactLayout ? 'bold' : 'normal'} noWrap>חבילות פרסום</Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PaymentsIndicator
