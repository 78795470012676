import React, { useCallback } from 'react'
import { Grid, Box } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'

import { PaymentType } from '~/types/Payment'
import { useModal } from '~/utils/useModal'
import { PaymentsProvider } from '~/components/modules/PaymentsProvider/context'
import { Send } from '~/components/core/Icons'
import Typography from '~/components/core/Typography'
import { useSendAllLeadsMessages } from '../data/use-send-all-leads-messages/use-send-all-leads-messages'
import SendToAllUpdatePlanModal from '../../components/SendToAllUpdatePlanModal'
import { ViewerProvider } from '~/components/core/ViewerProvider/context'

interface Props {
  leadsCount?: number
  onSendToAllMessage?: () => void
}

// eslint-disable-next-line complexity
// const isEnabled = (currentPlan?: PaymentType, leadsCount?: number, remainingNumberOfClicks?: number) => {
//   if (!leadsCount || !currentPlan) {
//     return false
//   }

//   if (currentPlan === 'free' && leadsCount > 15) {
//     return false
//   }

//   if (currentPlan === 'standard' && leadsCount > Number(remainingNumberOfClicks)) {
//     return false
//   }

//   if (currentPlan !== 'premium' && !remainingNumberOfClicks) {
//     return false
//   }

//   return true
// }

// eslint-disable-next-line complexity
const isEnabledUnlimited = (currentPlan?: PaymentType, leadsCount?: number, isAgent?: boolean) => {
  if (!leadsCount || !currentPlan) {
    return false
  }

  if (!isAgent) {
    return true
  }

  if (currentPlan === 'free' && leadsCount > 3) {
    return false
  }

  if (currentPlan === 'premium') {
    return true
  }

  return false
}

const SendMessageToAll = ({
  leadsCount, onSendToAllMessage
}: Props): JSX.Element => {
  const { userDetails } = React.useContext(ViewerProvider)
  const { currentPlan, refetch } = React.useContext(PaymentsProvider)
  const { id } = useParams<{ id: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const { showModal, hideModal, isOpen } = useModal()
  const { execute, loading } = useSendAllLeadsMessages({ assetId: id })
  const isButtonEnabled = isEnabledUnlimited(currentPlan, leadsCount, userDetails.isAgent)

  // useEffect(() => {
  //   setReachedLimit(isReachLimit(sendCount, remainingNumberOfClicks, primiumDaysLeft))
  // }, [remainingNumberOfClicks, sendCount, primiumDaysLeft])

  const handleSubmit = useCallback(() => {
    execute().then(() => {
      refetch()
      onSendToAllMessage?.()

      return enqueueSnackbar(`המודעה נשלחה בהצלחה לכולם`, {
        variant: 'success'
      })
    }).catch(() => {
      return enqueueSnackbar(`שגיאה, אנא נסו לשלוח הודעה לכולם מאוחר יותר`, {
        variant: 'error'
      })
    })
  }, [execute, enqueueSnackbar, refetch, onSendToAllMessage])

  const handleClick = useCallback(() => {
    if (isButtonEnabled) {
      return handleSubmit()
    }

    return showModal()
  }, [isButtonEnabled, handleSubmit, showModal])

  if (!leadsCount) {
    return <></>
  }

  return (
    <>
    <Box width='max-content' onClick={handleClick} style={{ cursor: 'pointer' }}>
      <Grid container spacing={1}>
        <Grid item style={{ display: 'inline-flex' }}>
          {
            loading ? <CircularProgress size='23px' /> : <Send color={isButtonEnabled ? '#4BB5AF' : '#949090'} />
          }
        </Grid>
        <Grid item alignItems='center' style={{ display: 'inline-flex' }}>
          <Typography color='textPrimary' style={{ fontSize: '12px' }}>
          שלח מודעה לכולם
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <SendToAllUpdatePlanModal isOpen={isOpen} onClose={hideModal} />
    </>
  )
}

export default SendMessageToAll
